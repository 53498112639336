@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap');
/* font-family: 'Amatic SC', sans-serif; */

@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap');
/* font-family: 'Dela Gothic One', sans-serif; non utilisé*/

@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&display=swap');
/* font-family: 'M PLUS Rounded 1c', sans-serif; */

//Test police
@import url('https://fonts.googleapis.com/css2?family=Lemon&display=swap');
/* font-family: 'Lemon', serif; */

@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,300&display=swap');
/* font-family: 'Roboto Flex', sans-serif; */

@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap'); //pour légende image
/*font-family: 'Cutive Mono', monospace;*/

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@1,700&display=swap'); // pour logo
/* font-family: 'Ubuntu', sans-serif; */

@import url('https://fonts.googleapis.com/css2?family=Aoboshi+One&display=swap'); // pour séparation
/* font-family: 'Aoboshi One', serif; */

@import url('https://fonts.googleapis.com/css2?family=Stick&display=swap');
/* font-family: 'Stick', sans-serif; */

//Responsive
$breakpoints: (
    mobile: 767px,
    tablet: 992px,
    smallDesktop: 1525px
);

@mixin mobile-only {
    @media screen and (max-width: map-get($breakpoints, mobile)) {
        @content;
    }
}

@mixin tablet-only {
    @media screen and (min-width: 768px) and (max-width: map-get($breakpoints, tablet)) {
        @content;
    }
}

@mixin small-desktop {
    @media screen and (min-width: 993px) and (max-width: map-get($breakpoints, smallDesktop)) {
        @content;
    }
}

@mixin small-desktopTwo {
    @media screen and (min-width: 1250px) and (max-width: map-get($breakpoints, smallDesktop)) {
        @content;
    }
}

$redColor: rgba(244, 67, 54, 1);
$blueColor: rgba(33, 150, 243, 1);
$navColor: #212E53;
$textColor: #f7e7cf;

// Body

// /* Masquer les éléments en dessous de 993px */
// @media (max-width: 992px) {
//     .cursor {
//         display: none;
//     }
// }

#ohayo-body {
    overflow-x: hidden;
    color: #f7e7cf;
    // cursor: none;
}

// Preload animation
.loader-span {
    overflow: hidden;
    display: block;
}

.loader-wrap {
    position: absolute;
    z-index: 10;
    height: 100vh;
    width: 100vw;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    background: transparent;
    z-index: 45;
}

.loader-wrap svg {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 110vh;
}

.loader-wrap svg>path {
    fill: #f7e7cf;
}

.loader-wrap .loader-wrap-heading h1 {
    font-size: 30px;
    z-index: 50;
    color: #000000;
    text-transform: uppercase;
    font-weight: lighter;
    font-family: "Ogg";
}

// Header
header {
    position: fixed;
    width: 100%;
    height: 100px;
    background-color: #000000;
    border-bottom: 1px solid #f7e7cf;
    z-index: 40;

    & #header-container {
        display: grid;
        grid-template-columns: 17% 30% 29% 24%;

        @include tablet-only {
            grid-template-columns: 40% 60%;
        }

        @include mobile-only {
            grid-template-columns: 40% 60%;
        }
    }
}

#headerTitle {
    font-family: 'Ubuntu', sans-serif;
    text-transform: uppercase;
    //font-size: 42px;
    //border-right: 1px solid #f7e7cf;
    //height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & span {
        margin-right: 1.5%;
    }

    @include tablet-only {
        border-right: 0;
        justify-content: flex-start;
        padding-left: 20%;
    }

    @include mobile-only {
        border-right: 0;
        justify-content: flex-start;
        padding-left: 20%;
    }

}

#dateTime {
    color: $textColor;
    display: flex;
    flex-direction: column;
    font-family: 'Ubuntu', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding-left: 33%;

    @include tablet-only {
        height: 90px;
        font-size: 12px;
        align-items: center;
        padding-left: 34%;
        padding-bottom: 0%;
        padding-top: 5%;
    }

    @include mobile-only {
        height: 40px;
        font-size: 8px;
        align-items: center;
        padding-left: 0%;
        padding-bottom: 0%;
        padding-top: 16%;
    }

}

#japanese {
    font-size: 11px;
    display: flex;
    align-items: center;
    padding-left: 12%;
    //justify-content: center;

    @include tablet-only {
        font-size: 7.5px;
        justify-content: flex-end;
        padding-left: 0%;
        padding-bottom: 4%;
        margin: 2%;
    }

    @include mobile-only {
        font-size: 5px;
    }
}

#headerNav {
    //padding-bottom: 7%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-size: 20px;
    padding-left: 15%;
    //padding-right: 20%;

    @include small-desktop {
        column-gap: 8%;
    }

    @include tablet-only {
        height: 90px;
        font-size: 15px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 0%;
        padding-top: 1.5%;

        a {
            & .navText {
                display: none;
            }

            & .icon {
                display: block;
                margin-top: -74%;
            }
        }
    }

    @include mobile-only {
        height: 40px;

        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 0%;
        padding-top: 14.5%;

        a {
            & .navText {
                display: none;
            }

            & .icon {
                display: block;
                margin-top: -91%;
            }
        }
    }

    a {
        text-decoration: none;

        & .navText {

            color: $textColor;
            //cursor: none;
        }
    }

    & .icon {
        display: none;
    }
}

// Menu for Small Screen
.toggle-btn {

    @include tablet-only {
        background: none;
        cursor: pointer;
        padding: 4px;
        width: 52px;
        height: 52px;
        border-radius: 200px;
        border: 2px solid #f7e7cf;
        margin-left: 73%;
    }

    @include mobile-only {
        background: none;
        cursor: pointer;
        padding: 5px;
        width: 27px;
        height: 27px;
        border-radius: 200px;
        border: 1px solid #f7e7cf;
        margin-left: 75%;
        margin-top: 4.6%;
    }

}

.hamburger {

    @include mobile-only {
        width: 15px;
        height: 15px;
    }

}


.hamburger path {

    @include tablet-only {
        fill: none;
        stroke: $textColor;
        stroke-linecap: round;
    }

    @include mobile-only {
        fill: none;
        stroke: $textColor;
        stroke-linecap: round;
    }

}

#navContainer {

    @include tablet-only {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100vh;
        display: none;
        z-index: 39;

        nav {
            position: relative;
            //z-index: 40;
            left: 10%;

            ul {
                list-style: none;

                li {
                    overflow: hidden;
                    transition: transform 300ms ease-in-out 0s;

                    &:active {
                        transform: translateX(50px);
                    }

                    a {
                        font-size: 75px;
                        text-decoration: none;
                        display: inline-block;
                        line-height: 1.3;
                        color: $textColor;
                    }
                }
            }
        }

        & .contact-container {
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;

            padding: 40px 31px;
            //z-index: 40;

            & .contactInfos {
                list-style: none;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                //font-size: 16px;


                li {
                    font-size: 28px;
                    color: $textColor;
                    margin-left: 60px;
                    color: $textColor;

                    a {
                        text-decoration: none;
                        color: $textColor;
                    }
                }

            }

        }

    }

    @include mobile-only {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100vw;
        height: 100vh;
        display: none;
        z-index: 39;

        nav {
            position: relative;
            //z-index: 40;
            left: 10%;

            ul {
                list-style: none;

                li {
                    overflow: hidden;
                    transition: transform 300ms ease-in-out 0s;

                    &:active {
                        transform: translateX(50px);
                    }

                    a {
                        font-size: 37px;
                        text-decoration: none;
                        display: inline-block;
                        line-height: 2.5;
                        color: $textColor;
                    }
                }
            }
        }

        & .contact-container {
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;

            padding: 40px 18px;
            //z-index: 40;

            & .contactInfos {
                list-style: none;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                //font-size: 16px;


                li {
                    font-size: 16px;
                    margin-left: 27px;
                    color: $textColor;

                    a {
                        text-decoration: none;
                        color: $textColor;
                    }
                }

            }

        }

    }

}

#navContainer nav li+li {

    @include tablet-only {
        margin-top: 100px;
    }

}

.container-inner {

    @include tablet-only {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 100px 29px;

    }

    @include mobile-only {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 70px 30px;

    }

}

.nav-bg {

    @include tablet-only {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
    }

    @include mobile-only {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
    }
}

.nav-bg span {

    @include tablet-only {
        display: block;
        height: 36%;
        width: 100%;
        background: #000000;
        opacity: 0.995;
    }

    @include mobile-only {
        display: block;
        height: 36%;
        width: 100%;
        background: #000000;
        opacity: 0.995;
    }

}

.letterPink {
    color: #de667e;
}

// Section 'hero'
canvas {
    position: absolute;
    // top: 0;
    // left: 0;
    z-index: -2;
}

@-moz-keyframes mouse-wheel {
    0% {
        top: 1px;
    }

    25% {
        top: 2px;
    }

    50% {
        top: 3px;
    }

    75% {
        top: 2px;
    }

    100% {
        top: 1px;
    }
}

@-o-keyframes mouse-wheel {

    0% {
        top: 1px;
    }

    25% {
        top: 2px;
    }

    50% {
        top: 3px;
    }

    75% {
        top: 2px;
    }

    100% {
        top: 1px;
    }
}

@keyframes mouse-wheel {

    0% {
        top: 1px;
    }

    25% {
        top: 2px;
    }

    50% {
        top: 3px;
    }

    75% {
        top: 2px;
    }

    100% {
        top: 1px;
    }
}

@-webkit-keyframes mouse-scroll {

    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes mouse-scroll {

    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes mouse-scroll {

    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes mouse-scroll {

    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes mouse-wheel {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(6px);
        -ms-transform: translateY(6px);
        transform: translateY(6px);
    }
}

.hero {
    height: 100vh;
    min-height: 30em;
    //color: #f5f7fa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    & .main {
        position: relative;
        left: 0;
        top: 5%;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include mobile-only {
            top: 0%;
        }

        & .ul {
            height: 35%;
            width: 100%;
            font-family: 'Ubuntu', sans-serif, 'Lemon', serif; //'Amatic SC', sans-serif;
            text-transform: uppercase;
            font-size: 60px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            margin-bottom: 2%;
            margin-top: 2.1%;
            padding: 0 10%;

            @include tablet-only {
                flex-direction: column;
                height: 52%;
            }

            @include mobile-only {
                flex-direction: column;
                font-size: 30px;
            }

            @include small-desktop {
                font-size: 45px;
                justify-content: space-evenly;
            }

            a {
                color: #f7e7cf;
                text-decoration: none;
                cursor: none;

                &:hover {
                    cursor: none;
                }
            }

            & .li {
                position: relative;
                cursor: pointer;
                transition: 1s;
                margin-right: 3%;
                //font-size: 20px;
                //color: burlywood;

                &:hover {
                    opacity: 1 !important;
                }
            }
        }

    }

    & .mouse_scroll {
        display: block;
        margin: 0 auto;
        width: 24px;
        height: 100px;
        position: relative;
        bottom: 2%;
        //margin-top: 125px;

        @include mobile-only {
            bottom: 14%;
        }


        & .m_scroll_arrows {
            display: block;
            width: 5px;
            height: 5px;
            -ms-transform: rotate(45deg);
            /* IE 9 */
            -webkit-transform: rotate(45deg);
            /* Chrome, Safari, Opera */
            transform: rotate(45deg);

            border-right: 2px solid white;
            border-bottom: 2px solid white;
            margin: 0 0 3px 4px;

            width: 16px;
            height: 16px;
        }

        & .unu {
            margin-top: 1px;
        }

        & .unu,
        .doi,
        .trei {
            -webkit-animation: mouse-scroll 1s infinite;
            -moz-animation: mouse-scroll 1s infinite;
            animation: mouse-scroll 1s infinite;

        }

        & .unu {
            -webkit-animation-delay: .1s;
            -moz-animation-delay: .1s;
            -webkit-animation-direction: alternate;

            animation-direction: alternate;
            animation-delay: alternate;
        }

        & .doi {
            -webkit-animation-delay: .2s;
            -moz-animation-delay: .2s;
            -webkit-animation-direction: alternate;

            animation-delay: .2s;
            animation-direction: alternate;

            margin-top: -6px;
        }

        & .trei {
            -webkit-animation-delay: .3s;
            -moz-animation-delay: .3s;
            -webkit-animation-direction: alternate;

            animation-delay: .3s;
            animation-direction: alternate;


            margin-top: -6px;
        }

        & .mouse {
            height: 42px;
            width: 24px;
            border-radius: 14px;
            transform: none;
            border: 2px solid white;
            top: 170px;
        }

        & .wheel {
            height: 5px;
            width: 2px;
            display: block;
            margin: 5px auto;
            background: white;
            position: relative;

            height: 4px;
            width: 4px;
            border: 2px solid #fff;
            -webkit-border-radius: 8px;
            border-radius: 8px;
        }

        & .wheel {
            -webkit-animation: mouse-wheel 0.6s linear infinite;
            -moz-animation: mouse-wheel 0.6s linear infinite;
            animation: mouse-wheel 0.6s linear infinite;
        }

    }
}

.wrapper {
    width: 100vw;
}

// slider 1
#sliderOne {
    width: 100vw;
    height: 100vh;

    @include mobile-only {
        margin-top: -12%;
    }
}

.gallery {
    height: 100%;
    //min-height: 30em;
    width: fit-content;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    @include mobile-only {
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 33% 33% 34%;
        row-gap: 14%;
        column-gap: 2%;
        margin-top: 22%;
    }
}

.gallery .item {
    //display: flex;
    margin-top: 1.9%;
    width: 893px;
    /*min-width: 25em;
    max-width: 47em;*/
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    height: 85vh;
    position: relative;

    @include tablet-only {
        width: 775px;
        height: 68vh;
        margin-top: 1.5%;
    }

    @include mobile-only {
        height: 46vh;
        width: 48vw;
    }
}


.gallery img {
    width: 72%;
    min-width: 25em;
    max-width: 47em;
    height: 86%;
    object-fit: cover;
    //padding: 1em 0;
    opacity: 1;
    border-radius: 5px;
    //filter: drop-shadow(0 0 0.25rem #f7e7cf);

    @include tablet-only {
        width: 91%;
    }

    @include mobile-only {
        width: 100%;
        height: 100%;
        min-width: 0;
        border-radius: 0;
        object-fit: cover;
    }
}

//Slider 2

#sliderTwo {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
}

.galleryTwo {
    height: 100vh;
    //width: 5000px;
    width: fit-content;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 0.2%;
    column-gap: 1.2%;

    // @include small-desktop {
    //     width: 3000px;
    // }

    // @include small-desktopTwo {
    //     width: 3000px;
    //     column-gap: 2%;
    // }

    @include tablet-only {
        width: 2400px;
    }

    @include mobile-only {
        width: 100%;
        /*89%*/
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 50% 50%;
        row-gap: 8%;
        column-gap: 6%;
        margin-top: 14%;
        padding-left: 1%;
        padding-right: 9%;
    }
}

.galleryTwo .item {
    margin-top: 1.2%;
    height: 72vh;
    width: 60vw;
    display: flex;
    flex-direction: column;
    //justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    overflow: hidden;


    @include mobile-only {
        height: 57vh;
        width: 48vw;
    }
}



.item3 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1);
    transition: all 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);

}

.item3:hover img {
    transform: scale(1.3);
}

.galleryTwo img {
    width: 100%;
    //min-width: 20em;
    height: 100%;
    object-fit: cover;
    //transition: all linear 0.7s;
    transition: 0.7s;
    -webkit-background-size: cover;
    background-size: cover;
    //display: none;
}


// Slider 3

.canvas2 {
    //position: fixed;
    z-index: -1;
    pointer-events: none;
}

#sliderThree {
    //position: relative;
    width: 100vw;
    height: 100vh;
}

.galleryThree {
    height: 100%;
    //min-height: 30em;
    width: 100%;
    display: flex;
    //flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;

    @include tablet-only {
        flex-direction: column;
        justify-content: center;
        gap: 5%;
    }

    @include mobile-only {
        flex-direction: column;
        justify-content: center;
        gap: 5%;
        width: 100vw;
        height: 100vh;
        margin-top: -9%;
    }
}

.galleryThree .item img {
    display: none;
    object-fit: cover;
}

.galleryThree h2 {
    font-family: 'Cutive Mono', monospace;
    font-size: 30px;
    text-transform: uppercase;

    @include tablet-only {
        font-size: 36px;
    }

    @include mobile-only {
        font-size: 22px;
    }
}

.galleryThree p {
    visibility: hidden;

    @include tablet-only {
        visibility: visible;
        font-size: 25px;
        padding-top: 3%;
    }

    @include mobile-only {
        visibility: visible;
        font-size: 15px;
        padding-top: 3%;
    }
}


.project-image {
    opacity: 0%;
    position: absolute;
    //top: -100%;
    top: 50%;
    left: 50;
    transform: translate(-50%, -50%);
    pointer-events: none;
    user-select: none;
    object-fit: cover;
}

.modal {
    background: rgba(0, 0, 0, 1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    z-index: 45;
    transition: 0.25s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;

    // @include small-desktop {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    // }

    & .full-img {
        position: absolute;
        //height: 82%;
        max-height: 82%;
        max-width: 82%;
        // top: 5%;
        // left: 22.5%;
        transform: scale(0);
        transition: all 0.25s ease-out;
        object-fit: contain;

        @include tablet-only {
            // top: 7%;
            // left: 1.5%;
            // width: 97%;
            max-height: 82%;
            max-width: 82%;
        }

        @include mobile-only {
            // top: 2%;
            // left: 0;
            max-height: 100%;
            max-width: 100%;
        }

        @include small-desktop {
            max-height: 68%;
            max-width: 82%;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%) scale(0);
        }

    }

    & .full-img.open {
        transform: scale(1);
        //object-fit: contain;

        @include mobile-only {
            object-fit: contain;
            background-color: black;
        }

        // @include small-desktop {
        //     transform: translate(-50%, -50%) scale(1);
        // }
    }

    & p {
        //color: #f5f7fa;
        font-size: 2rem;
        position: absolute;
        bottom: 15%;
        left: 50%;
        transform: translate(-50%, -5%);

        @include tablet-only {
            bottom: 26%;
        }

        @include mobile-only {
            font-size: 1.2rem;
            //left: 20%;
            bottom: 25%;
        }

        @include small-desktop {
            font-size: 1.5rem;
        }

    }
}

.modal.open {
    opacity: 1;
    pointer-events: all;
    z-index: 39;

    & p {
        @include small-desktop {
            bottom: 16%;
        }
    }
}

// For Slider 2
.modalTwo {
    background: rgba(0, 0, 0, 1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    pointer-events: none;
    z-index: 45;
    transition: 0.25s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;

    & .full-img-two {
        position: absolute;
        height: 82%;
        top: 12%;
        transform: scale(0);
        transition: all 0.25s ease-out;
        max-height: 75%;
        transform: translate(-50%, -50%);

        @include tablet-only {
            // top: 7%;
            // left: 1.5%;
            width: 97%;
        }

        @include mobile-only {
            height: 100%;
            width: 100%;
            left: 0;
        }

        @include small-desktop {
            max-height: 82%;
            max-width: 82%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

    }

    & .full-img-two.open {
        transform: scale(1);
        object-fit: contain;

        @include mobile-only {
            object-fit: contain;
            background-color: #000000;
        }

        @include small-desktop {
            transform: translate(-50%, -50%) scale(1);
        }
    }

    & p {
        font-size: 1.2rem;
        position: absolute;
        bottom: 8%;
        left: 50%;
        transform: translate(-50%, -5%);

        @include mobile-only {
            font-size: 1rem;
            top: 72%;
            left: 51%;
            bottom: 0;
        }

        @include small-desktop {
            bottom: 7%;
            font-size: 1.2rem;
        }

        // @media screen and (min-width: 993px) and (max-width: 1024px) {
        //     bottom: 8%;
        // }

        @include tablet-only {
            bottom: 23%;
            font-size: 2rem;
        }
    }

    & .captionTwo.open {
        @include mobile-only {
            z-index: 40;
        }
    }
}

.modalTwo.open {
    opacity: 1;
    pointer-events: all;
    z-index: 39;
}


footer {
    height: 120px;
    padding-top: 1%;

    .info {
        color: #f5f7fa;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-family: 'Cutive Mono', monospace;

        & a {
            text-decoration: none;
            color: $textColor;

            & p {
                margin-bottom: 12px;
            }
        }

        .ai {
            font-size: 15px;
            color: $textColor;
            font-family: 'Cutive Mono', monospace;
        }

        @include mobile-only {
            font-size: 15px;
        }
    }


}

.cursor {
    pointer-events: none;

    &__ball {
        position: fixed;
        top: 0;
        left: 0;
        mix-blend-mode: difference;
        z-index: 50;

        & circle {
            fill: #f5f7fa;
        }
    }
}

/* Popup window when clicked */
.img-window {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 1);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.img-window img {
    //max-height: 80%;
    //max-width: 100vw;
    max-height: 76%;
    width: 100%;
    object-fit: contain;
    opacity: 1;
    margin-top: 2%;
    //background-color: #000000;

    @include small-desktop {
        max-height: 52%;
        //margin-top: 3%;
    }
}

.img-window p {
    font-size: 1.2rem;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -5%);

    @include mobile-only {
        font-size: 1rem;
        bottom: 25%;
    }

    @include tablet-only {
        font-size: 2rem;
        bottom: 20%;
    }

    @media screen and (min-width: 993px) and (max-width: 1024px) {
        bottom: 8%;
    }

    @include small-desktop {
        bottom: 18%;
        font-size: 1.2rem;
    }

}


/* Buttons image */
.img-btn-next,
.img-btn-prev {
    display: none;
    /* Masquer les boutons */
}

// .img-btn-next {
//     font-size: 25px;
//     display: block;
//     background-color: #111111;
//     position: fixed;
//     top: 48vh;
//     z-index: 60;
//     color: white;
//     text-transform: uppercase;
//     border: 1px solid white;
//     border-radius: 50%;
//     padding: 8px 15px;
// }

// .img-btn-next:hover {
//     //opacity: 0.8;
//     color: rgb(166, 181, 31);
//     border: 1px solid rgb(166, 181, 31);
// }

// .img-btn-prev {
//     font-size: 25px;
//     display: block;
//     background-color: #111111;
//     position: fixed;
//     top: 48vh;
//     z-index: 60;
//     color: white;
//     text-transform: uppercase;
//     border: 1px solid white;
//     border-radius: 50%;
//     padding: 8px 15px;
// }

// .img-btn-prev:hover {
//     //opacity: 0.8;
//     color: rgb(166, 181, 31);
//     border: 1px solid rgb(166, 181, 31);
// }


// /* Masquer les boutons en version mobile */
// @media (max-width: 767px) {

//     .img-btn-next,
//     .img-btn-prev {
//         display: none;
//     }
// }

// Section between each slider

@keyframes infiniteScroll {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(calc(0px - 30%));
    }
}

@keyframes infiniteScrollTwo {
    from {
        transform: translateX(calc(0px - 50%));
    }

    to {
        transform: translateX(0);
    }
}

.separation {
    //background-color: rgba(247, 231, 207, 0.6);
    width: 100vw;
    height: 10vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //align-items: center;
    margin-top: 6%;
    border-top: #f7e7cf 1px solid;
    border-bottom: #f7e7cf 1px solid;

    @include mobile-only {
        margin-top: 71%;
        height: 6vh;
    }
}

.separationTwo {
    margin-top: 8%;
    font-family: 'Stick', sans-serif;

    @include mobile-only {
        margin-top: 35%;
    }
}

.text {
    //white-space: nowrap;
    font-size: 35px;
    color: #f7e7cf;
    font-family: 'Stick', sans-serif;
    display: flex;

    @include mobile-only {
        font-size: 15px;
    }

    &__item {
        white-space: nowrap;
        //text-shadow: $textColor 1px 0 10px;

        & span:hover {
            //-webkit-text-stroke: 1px white;
            color: white;
            text-shadow: none;
        }
    }
}

.rightToLeft {
    animation-name: infiniteScroll;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &:hover {
        animation-play-state: paused;
    }
}