@import "./pages/ohayo";
@import "./pages/konnichiwa";
@import "./pages/konbanwa";
@import "./pages/allpages";


@import url('https://fonts.googleapis.com/css2?family=Chokokutai&display=swap');
/* font-family: 'Chokokutai', cursive; */
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
/* font-family: 'Pacifico', cursive; */


$redColor: rgba(244, 67, 54, 1);
$blueColor: rgba(33, 150, 243, 1);
$navColor: #212E53;

// ::-webkit-scrollbar {
//     width: 0.4em;
//     height: 0;
//     animation: barFadeIn 0.1s 1.1s forwards;
// }

// ::-webkit-scrollbar-track {
//     background: white;
//     margin-block: 0.5em;
// }

// ::-webkit-scrollbar-thumb {
//     background: #212e53c4;
//     border-radius: 100vw;

// }

// @keyframes barFadeIn {
//     0% {
//         opacity: 0;
//     }

//     100% {
//         opacity: 1;
//     }
// }

@supports (scrollbar-color: red blue) {
    * {
        scrollbar-color: #f7e7cf rgb(0, 0, 0);
        scrollbar-width: auto;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #000000;
}

/* Personnalisation de la scrollbar pour les navigateurs basés sur WebKit */
::-webkit-scrollbar {
    width: 0.2em;
    /* Largeur de la scrollbar */
    height: 0;
}

::-webkit-scrollbar-track {
    background: #000000;
    /* Couleur de l'arrière-plan de la scrollbar */
    //margin-block: 0.1em;
}

::-webkit-scrollbar-thumb {
    background: #f7e7cf;
    /* Couleur de la poignée de la scrollbar */
    //border-radius: 10px;
    border-radius: 100vw;
    /* Arrondir les coins de la poignée */
}

// body::-webkit-scrollbar-thumb:hover {
//     background: #555;
//     /* Couleur de la poignée de la scrollbar au survol */
// }